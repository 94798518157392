<template>
    <div class="content">
        <div class="contentbox">
            <div class="SearchBar" style="display: flex">
                <span style="margin: 6px 10px 0 20px ">工程编号/名称:</span>
                <el-input style="width: 200px" placeholder="请输入" size="small"  v-model="searchBox.keyWord"></el-input>
                <span style="margin: 6px 10px 0 20px "> 工程类型：</span>
                <el-select
                        v-model="searchBox.projectType"
                        clearable
                        placeholder="请选择"
                        size="small"
                >
                    <el-option
                            v-for="item in typeList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                    >
                    </el-option>
                </el-select>
                <el-button type="primary" class="SearchBtn" size="small" @click="search" style="margin-left: 10px">查询</el-button>
            </div>
            <!--数据表格-->
            <el-table
                    ref="tableData"
                    :data="tableData"
                    style="width: 100%;margin: 15px 0;"
                    border
                    height="calc(100vh - 310px)"
                    :stripe="true">
                <el-table-column type="index" label="序号" width="70" align="center"> </el-table-column>
                <el-table-column prop="projectNumber" label="工程编号" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="projectName" label="工程名称" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="projectTypeName" label="工程类型" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="siteManager" label="现场管理员" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="streamTime" label="创建时间" width="150" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="操作"  align="left" width="320">
                    <template slot-scope="{row}">
                        <el-button v-if="row.installId" type="primary" size="small" @click="dengji(row,row.installId)">安装资料</el-button>
                        <el-button v-if="row.drillingId" type="primary" size="small" @click="dengji(row,row.drillingId)">定向钻资料</el-button>
                        <el-button v-if="row.earthworkId" type="primary" size="small" @click="dengji(row,row.earthworkId)">土方资料</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pageBox">
                <el-pagination
                        :current-page="searchBox.current"
                        :background="true"
                        :page-sizes="[30, 50, 100]"
                        :page-size="searchBox.size"
                        layout="total, prev, pager, next, sizes, jumper"
                        :total="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                >
                </el-pagination>
            </div>
        </div>
      <el-dialog
          title="资料查看"
          :visible.sync="dialogVisible"
          :close-on-click-modal="false"
          width="50%"
      >
        <div style="height: 500px;overflow: auto">
        <!-- v-model="activeNames" -->
          <el-collapse accordion >
            <el-collapse-item v-for="(item,index) in Datalist" :key="index" :name="index">
              <template slot="title">
                <span class="title">{{item.templateName}}</span>
                <!--         修改和查看详情时候显示图标 审核通过，不过，待审核三种状态-->
                <el-tag v-if="item.templateCheck==1" type="success" style="margin-left:10px ">已通过</el-tag>
                <el-tag v-if="item.templateCheck==2" type="warning" style="margin-left:10px ">未审核</el-tag>
                <el-tag v-if="item.templateCheck==3" type="danger" style="margin-left:10px ">已驳回</el-tag>
              </template>
              <div>
                <!--                            //描述-->
                <p class="direct">{{item.templateDescribe}}</p>
                <!--                            驳回原因-->
                <p v-if="item.templateCheck==3">驳回原因：{{item.dismissReason}}</p>
                <!--                            登记||驳回的时候重新登记 审核完成的不显示登记-->
                <el-table
                    ref="tableData"
                    :data="item.templateRecord"
                    style="width: 100%;"
                    border
                    :stripe="true">
                  <el-table-column prop="dataName" label="标题"  align="center" show-overflow-tooltip> </el-table-column>
                  <el-table-column prop="createTime" label="创建时间" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="updateBy" label="末次修改" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="updateTime" label="修改时间" align="center" show-overflow-tooltip></el-table-column>
                  <el-table-column label="操作" align="left" width="150">
                    <template slot-scope="{row}">
                      <el-button size="small" type="primary" @click="seeMenu(item,row)">查看</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">关 闭</el-button>
  </span>
      </el-dialog>

      <el-dialog
          :visible.sync="seeMenuDialogVisible"
          width="30%"
          :close-on-click-modal="false"
          :before-close="closEeeMenuDialogVisible"
      >
        <p class="DetiltemplateName">{{DetiltemplateName}}</p>
        <p class="direct" style="margin: 10px 0">{{DetiltemplateDescribe}}</p>
        <p style="margin: 20px 0">标题：{{submitObj.dataName}}</p>
        <div v-for="(item,index) in submitObj.workCompletionTemplatesSeedList" :key="index" style="margin: 20px 0">
          <div v-if="item.templateSeedType==1">
            <!-- 图片-->
            <div>{{item.templateSeedName}}:</div>
            <div v-if="item.templatesUrl.length>0">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="item.templatesUrl[0]"
                  :preview-src-list="item.templatesUrl">
              </el-image>
              <span>共{{item.templatesUrl.length}}项</span>
            </div>
          </div>
          <div v-if="item.templateSeedType==2">
            <!--              文本描述-->
            <span>{{item.templateSeedName}}：</span>{{item.templatesUrl}}
          </div>
          <div v-if="item.templateSeedType==3">
            <!--              PDF-->
            <div>{{item.templateSeedName}}:</div>
            <div v-for="(i,k) in item.templatesUrl" :key="k">
            <a :href="i" download="" target="_back">查看PDF</a>
          </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="seeMenuDialogVisible = false">关 闭</el-button>
        </span>
      </el-dialog>
    </div>
</template>

<script>
import {dataInquiryExamine, dataInquiryList} from '../../RequestPort/earlier/index'
    import { getDict } from "../../apis/commonType";
    export default {
        name: "menuSerach",
        data(){
            return{
              DetiltemplateName:'',
              DetiltemplateDescribe:'',
                activeNames:0,
                dialogVisible:false,
              seeMenuDialogVisible:false,
                searchBox:{
                    current:1,
                    size: 100
                },
                typeList:[],
                total:null,
                tableData:[],
                Datalist:[],
                submitObj:{
                  dataName:'',
                  workCompletionTemplatesSeedList:[]
                }
            }
        },
        mounted(){
            //字典查工程类型
            getDict({ parentCode: "2100" }).then((res) => {
                this.typeList = res.data;
            });
            this.loadList()
        },
        methods:{
          //查看资料
          seeMenu(item,row){
            this.DetiltemplateName = item.templateName
            this.DetiltemplateDescribe = item.templateDescribe
            this.submitObj = JSON.parse(JSON.stringify(row))
            this.submitObj.workCompletionTemplatesSeedList.forEach(item=>{
            //  if(item.templatesUrl && typeof item.templatesUrl == 'string'){
                if(item.templatesUrl && typeof item.templatesUrl == 'string'){
                  if(item.templateSeedType==2){

                  }else {
                    this.$set(item,'templatesUrl',item.templatesUrl.split(','))
                  }
                }else if(!item.templatesUrl){
                  if(item.templateSeedType==2){

                  }else{
                    this.$set(item,'templatesUrl',[])
                  }
                }
           //   }
            })
            this.seeMenuDialogVisible = true
          },
          closEeeMenuDialogVisible(){
            this.submitObj = {
              dataName:'',
              workCompletionTemplatesSeedList:[]
            }
            this.seeMenuDialogVisible = false
          },
          loadList(page){
            if(page){
              this.searchBox.current = 1
            }
            dataInquiryList(this.searchBox).then(res=>{
              this.tableData = res.data.records
              this.total = res.data.total
            })
          },
          dengji(row,id){
            this.dialogVisible = !this.dialogVisible
            dataInquiryExamine({id:row.id,constructionId:id}).then(res=>{
             this.Datalist = res.data
            })
          },
          search(){
              this.loadList(1)
          },
          handleSizeChange(val){
            this.searchBox.size = val
            this.loadList()
          },
          handleCurrentChange(val){
            this.searchBox.current = val
            this.loadList()
          },
        }
    }
</script>

<style scoped>
.direct{
  background-color: #F3F7FF;
  border: 1px dashed #C1DFFF;
  padding: 0 11px;
  color: #818181;
  border-radius: 4px;
}
.DetiltemplateName{
  margin-left: 10px;
  color: #303133;
  font-size: 16px;
  position: relative;
}
.DetiltemplateName:before{
  content: '';
  position: absolute;
  left: -10px;
  top: 5px;
  width: 3px;
  height: 16px;
  background: #8AC2FF;
}
    .content{
        width: 100%;
        background: #f3f4f8;
    }
    .contentbox {
        margin: 10px;
        padding: 10px;
        background-color:#fff;
        position: relative;
    }
</style>